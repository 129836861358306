import React from "react"
import "./settings.scss"
import Fader from '../fader/fader'
import config from '../../globals/variables'
// import { navigate } from "gatsby"
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink"

const Settings = ({categories, settings, currentPage, className }) => {

  // const [ state, setState ] = useState({
  //   isOpenMenu: "",
  //   contrast: config.contrast,
  //   order: config.order,
  //   // soft: config.soft,
  //   selected: config.selected
  // })
  // const allCategories = categories

  const changeView = (settings, value) => {
    if(typeof document === "undefined") return false
    settings.forEach((setting, i) => {
      document.body.classList.remove(setting)
    })
    document.body.classList.add(settings[value])
  }

  const changeContrast = (value) => {
    changeView(config.contrast, value)
  }

  const changeOrder = (value) => {
    changeView(config.order, value)
  }

  return(
    <>
      <div className="viewSwitch">
        <TransitionLink to={'/'} >
          <div className={currentPage === '' ? 'active' : ''}>
            {/* IMG */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <polyline points="0 31 10 20 34 50"/>
              <polyline points="17 28 34 11 50 27"/>
              <circle cx="15" cy="10" r="5"/>
            </svg>

          </div>
        </TransitionLink>
        <TransitionLink to={'/list'} >
          <div className={currentPage === 'list' ? 'active' : ''}>
            {/* TXT */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              {/* <polyline points="0 31 10 20 34 50"/>
              <polyline points="17 28 34 11 50 27"/> */}
              <circle cx="8" cy="10" r="2"/>
              <circle cx="8" cy="20" r="2"/>
              <circle cx="8" cy="30" r="2"/>
              <circle cx="8" cy="40" r="2"/>
              <line x1="15" y1="10" x2="40" y2="10" />
              <line x1="15" y1="20" x2="40" y2="20" />
              <line x1="15" y1="30" x2="40" y2="30" />
              <line x1="15" y1="40" x2="40" y2="40" />

            </svg>
          </div>
        </TransitionLink>
      </div>
      <div className={`settings ${className}`} >
        <div className="menu__pages" >
          <Fader from="Order" to="Chaos" val={config.selected.order} min={0} max={config.order.length-1} dataId={0}  data-key={0} onChange={changeOrder}/> 
          <Fader from="Day" to="Night" val={config.selected.contrast} min={0} max={config.contrast.length-1}  dataId={50}  data-key={1} onChange={changeContrast}/>
        </div>
      </div>
    </>
  )
}

export default Settings
