import React from "react"
// import { relative } from "path";

// import config from '../../globals/variables'

const width = 3000
const height = 3000
const scale = 0.35

const Pattern = ({ id, position }) => {

    return(
        <div style={{ position: position, overflowY: "hidden", width: "100%" }}>
            <svg width={`${width}px`}  viewBox={`0 0 ${width} ${height}`} style={{position: "relative" }}>
                <defs>
                    <pattern 
                        id={`muster_${id}`} 
                        x="3" 
                        y="3" 
                        width="9" 
                        height="9"
                        patternUnits="userSpaceOnUse"  
                        patternTransform={`rotate(60) scale(${scale})`} >
                        {/* <rect x="1" y="1" width="5" height="5" stroke="" /> */}
                        <rect x="1" y="1" width="2" height="2" stroke="" />
                    </pattern>
                </defs>
                <rect fill={`url(#muster_${id})`} width={width} height={height} />
            </svg>
        </div>
    )
} 

export default Pattern