
import React from "react"
import PropTypes from "prop-types"

// import Intro from "../layout/intro"
import "./layout.scss"

// const time = 3000
// const transition = 600

const Layout = (props) => {

  const { children, className } = props



  return (
    <>
      {/* {!state.pageVisited && <Intro hide={state.hideIntro} />} */}
      <div className={`layout ${className}`} >
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout



  // const [ state, setState ] = useState({
  //   hideIntro: false,
  //   pageVisited: false
  // })

  // useEffect(() => {
  //   let fade = setTimeout(() => {
  //     setState({
  //       ...state,
  //       hideIntro: true
  //     })
  //   }, time)

  //   let hide = setTimeout(() => {
  //     setState({
  //       ...state,
  //       pageVisited: true
  //     })
  //   }, time+transition)

  // }, [state])