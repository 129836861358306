
const contrast =  [ 
    "black",
    "monochrome",
    // "colorful",
    "rainbow",
    "white"
]

const order = [
    'clean',
    'structured',
    // 'diversified',
    'messy',
    'chaos'
]

const colors = ['black', 'blue', 'rgb(255,160,122)', 'white']

const config = {
    contrast,
    order,
    colors,
    selected: {
        category: 1,
        order: 0,
        contrast: 0,
    }

}

export default config