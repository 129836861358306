
import React from "react"
import style from "./fader.module.scss"
// import config from '../../globals/variables'

const InputRange = (props) => {
    
    const returnValue = (e) => {
        props.onChange(parseInt(e.currentTarget.value))
    } 

    return (
        <div className={style.range}>
            {/* asfasf */}
            <label>
                <span>{props.from}</span>
                <input type="range" className={style.rangeSlider} defaultValue={props.val} min={props.min} max={props.max} data-id={props.dataId}  data-key={props.dataKey} onChange={returnValue} />
                <span>{props.to}</span>
            </label>
        </div>
    )
}

export default InputRange
