import React from 'react';
import Layout from './src/components/layout/layout'
import Header from './src/components/layout/header'
import SEO from "./src/components/layout/seo"
import Pattern from './src/components/layout/pattern';

export const wrapPageElement = ({ element, props }) => {
        

    return (
        <Layout {...props}>
            <SEO title="Architecture Office" />
            <Header siteTitle="Architecture Office" className={props.className} />
            <main >
                {element}
                        
                <div className="background">
                    <Pattern />

                </div>
            </main>
        </Layout>
    )
}