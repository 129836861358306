import React from "react"
import posed from 'react-pose';
import { TransitionState } from "gatsby-plugin-transition-link";

const FadingContent = posed.div({
  exiting:  { opacity: 0 },
  exited:   { opacity: 0 },
  entering: { opacity: 0  },
  entered:  { opacity: 1 },
  transition: {
    type: 'spring',
    values: [0, 0, 100, 100],
    stiffness: 400,
    dampness: 500
  }
})

const Transition = ({ children }) => {

  return(
    <TransitionState>
      {({ transitionStatus }) => {
        
        // console.log(transitionStatus)
        
        return(
          <FadingContent pose={transitionStatus}>
              {children}
          </FadingContent>
        )
      }}
    </TransitionState>
  )
}


export default Transition

const TRANSITION_LENGTH = 0.6
// const TRANSITION_LENGTH = 0

const exitTransition = {
  length: TRANSITION_LENGTH
}

const entryTransition = {
    length: TRANSITION_LENGTH
}

export { exitTransition, entryTransition }


