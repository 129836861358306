
import React, { useEffect, useState } from "react"
import "./header.scss"
import Settings from "../settings/settings"

// Page Transitions
import TransitionLink from 'gatsby-plugin-transition-link'
import { exitTransition, entryTransition} from '../transition/transition'

const Header = ({ siteTitle }) => {

  const [ currentPage, setCurrentPage ] = useState(null)

  useEffect(() => {


    const handlePage = () => {

      const page = window.location.href.split('/').pop()

      if(page !== currentPage) {
        setCurrentPage(page)
      };
    }

    handlePage()

    return () => {
      handlePage()
    }

  })

  const isArchitecturePage = currentPage === '' || currentPage === 'list'
  const isOfficePage = currentPage === 'info'

  return(
    <header>
      <nav>
        <DesktopHeader currentPage={currentPage} />
        <DesktopMobile currentPage={currentPage} />
        <div >
          <h1>

            <div>
              <TransitionLink 
              to="/"
              exit={exitTransition}
              entry={entryTransition}
              className={isArchitecturePage ? "activePage" : ""}
              >
                <div>
                  <span className="initial">A</span><span>rchitecture </span>
                </div>
              </TransitionLink>
              <TransitionLink 
              to="/info"
              exit={exitTransition}
              entry={entryTransition}
              // activeClassName="activePage"
              className={isOfficePage ? "activePage" : ""}
              >
                  <div>
                    <span className="initial">O</span><span>ffice</span>
                  </div>
              </TransitionLink>
            </div>
          </h1>
        </div>
      </nav>
    </header>
  )
}

export default Header


const DesktopHeader = ({ currentPage }) => {

  return (
    <div className="header__desktop">
      <Settings 
      currentPage={currentPage}
      // categories={["lab", "default", "office"]} 
      categories={["office", "default", "lab"]} 
      settings={{mood: true, category: true, order: true, soft: true}}
      />
    </div>
  )
}


const DesktopMobile = ({ currentPage }) => {


  const [ isOpen, setIsOpen ] = useState(false)

  const handleMenu = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    // Close Menu on Page change
    setIsOpen(false)
  }, [currentPage])


  return (
    <>
      <div
      className={`hamburger ${isOpen ? 'isOpen' : ''}`}
      onClick={handleMenu}
      onKeyDown={handleMenu}
      role="menu"
      tabIndex={0}
      >
        <div>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
      <div className={`header__mobile ${isOpen ? 'isOpen' : ''}`}>
        <Settings 
        currentPage={currentPage}
        // categories={["lab", "default", "office"]} 
        categories={["office", "default", "lab"]} 
        settings={{mood: true, category: true, order: true, soft: true}}
        />
      </div>
    </>
  )
}



